import { ChangeEventHandler, FC, FormEventHandler } from 'react' // we need this to make JSX compile
import * as yup from 'yup'
import { useFormik } from 'formik'
import Button from 'components/base/forms/Button'
import InputGroup from 'components/base/forms/InputGroup'
import { useLoginMutation } from 'common/services/useUser'
import { ErrorMessage, SuccessMessage } from 'components/base/Messages'
import Link from 'next/link'
import { preventDefault } from 'common/utils/forms/preventDefault'
import AuthFormContainer from 'components/page/AuthFormContainer'
import { useRouter } from 'next/router'

type LoginType = { message?: string; error?: string }
const schema = yup.object().shape({
  email: yup
    .string()
    .email('Email must be valid')
    .required('Email address is required'),
  password: yup.string().required('Password is required'),
})

const Login: FC<LoginType> = ({ error, message }) => {
  const router = useRouter()
  const [login, { data, error: userError, isLoading: userLoading }] =
    useLoginMutation()
  const { errors, handleBlur, handleChange, handleSubmit, setTouched, values } =
    useFormik({
      initialValues: {
        email: '',
        password: '',
      },
      onSubmit: () => {
        login({ ...values }).then((e: any) => {
          if (!e.error) {
            router.replace(
              `/auth/otp?email=${encodeURIComponent(values.email)}&token=${e
                .data?.token}`,
            )
          }
        })
      },
      validateOnMount: true,
      validationSchema: schema,
    })

  // useEffect(() => {
  //   console.log(userError)
  //   tryConfirmEmailRedirect(userError)
  // }, [userError])
  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    preventDefault(e)
    const newTouched = {}
    Object.keys(errors).map((v) => {
      // @ts-ignore
      newTouched[v] = true
    })
    setTouched(newTouched)
    handleSubmit(e)
  }

  const changeHandler: ChangeEventHandler = (e) => {
    handleChange(e)
  }

  return (
    <AuthFormContainer>
      <form className='d-flex flex-column gap-4' onSubmit={onSubmit}>
        <SuccessMessage>{message}</SuccessMessage>
        <InputGroup
          title='Email Address'
          name='email'
          type='email'
          onChange={changeHandler}
          onBlur={handleBlur}
          value={values.email}
          isValid={!errors.email}
        />
        <InputGroup
          title='Password'
          type='password'
          name='password'
          onChange={changeHandler}
          onBlur={handleBlur}
          value={values.password}
          isValid={!errors.password}
        />
        <ErrorMessage>{error || userError}</ErrorMessage>

        <div className=''>
          <Link href={'/auth/forgot-password'}>
            <a href='/auth/forgot-password'>Forgot your password?</a>
          </Link>
        </div>
        <Button
          className='w-100'
          disabled={userLoading || !!data}
          type='submit'
        >
          Sign in
        </Button>
      </form>
    </AuthFormContainer>
  )
}

export default Login
