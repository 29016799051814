import 'project/polyfill'
import { NextPageWithLayout } from 'types/nextPageWithLayout'
import { useUser } from 'common/services/useUser'
import SubscriptionTable from 'components/project/SubscriptionTable'
import { ReactElement } from 'react'
import { Profile } from 'common/types/responses'
import Aside from 'components/nav/Aside'
import Login from '../examples/auth/Login'
import { useRouter } from 'next/router'
import { defaultLayout } from './_app'

export type HomePageType = {}

const HomePage: NextPageWithLayout<HomePageType> = ({}) => {
  const { data } = useUser({})
  const router = useRouter()
  if (!data) {
    return <Login />
  } else {
    if (router.query.redirect) {
      router.replace(`${router.query.redirect}`)
      return null
    }
    return (
      <div className='container-fluid page-container'>
        <SubscriptionTable />
      </div>
    )
  }
}

HomePage.getLayout = (
  page: ReactElement,
  pageProps,
  user: Profile | undefined,
) => {
  if (user) {
    return defaultLayout(page)
  }
  return page
}

export default HomePage
